<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
                <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`Удалить ${selectAll ? 'все' : 'выбранные'} записи?`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #cell(name_ru)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <b-form-input v-if="variantAttribute"
                                      class="text-right"
                                      style="min-width: 90%"
                                      :value="data.item.name_ru"
                                      @change="checkDoubles($event, data.item)"
                                      @keyup.enter.exact="keyup13">
                        </b-form-input>
                        <div v-else>{{ data.value }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Такая запись уже в базе существует</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(unit)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.unit"
                                  @change="v => data.item.unit = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[а-яА-Я./ ]+$')">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(amount)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.amount"
                                  @change="v => data.item.amount = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'amount', data.item.amount, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(cost_avg)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost_avg"
                                  @change="v => data.item.cost_avg = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'cost_avg', data.item.cost_avg, 3)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                Добавить файл(ы)
                            </b-dropdown-item>
                            <b-dropdown-item v-if="variantAttribute" @click="deleteItemWithAttachedFiles('Удалить запись?', data.item, data.index)">
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="5">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
// import BudgetAttachFile from "./components/budget-attach-file";
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_169',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-169',
                name_ru: 'Расчет расходов по специфике "Прочие текущие затраты"',
                name_kk: '"Өзге де ағымдағы шығыстар" ерекшелiгi бойынша шығыстарды есептеу'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'unit',
                    label: 'Единица измерения'
                },
                {
                    key: 'amount',
                    label: 'Количество'
                },
                {
                    key: 'cost_avg',
                    label: 'Средняя стоимость за единицу (тысяч тенге)'
                },
                {
                    key: 'total',
                    label: 'Общая стоимость (тысяч тенге)'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },
        addItem() {
            const that = this;
            const item = { id: this.newRowStartId };
            this.newRowStartId--;
            that.itemUpdate(item);
            Object.defineProperty(item, 'total', {
                get: function () {
                    const total = that.number(item.amount) * that.number(item.cost_avg);
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });
            that.budgetForm.push(item);
            this.checkDoubles();
        },

        checkDoubles(newVal, item) {
            if (newVal !== undefined) item.name_ru = newVal.trim()
            const firstEntryItm = {}
            this.budgetForm.forEach((itm, idx) => {
                if (itm.name_ru === undefined || itm.name_ru === null) itm.name_ru = ""
                const name = itm.name_ru
                if (!firstEntryItm.hasOwnProperty(name)) {
                    firstEntryItm[name] = idx
                    itm.checkForDuplicates = false;
                } else {
                    itm.checkForDuplicates = true
                    const firsDoubleIdx = firstEntryItm[name]
                    this.budgetForm[firsDoubleIdx].checkForDuplicates = true
                }
            });
        },

        afterDeleted() {
            this.checkDoubles();
        },

        downloadRep() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма ' + this.form.code.replace('-', '_') + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        inputFixed(item, field, value, digit, max = 0) {
            // item - строка
            // field - наименование поля
            // value - глвле значение
            // digit - количество цифр после запятой
            // max - максимальное значение
            this.inputFixedVldtn(item, field, value, digit);
            if ((max > 0) && (value > max)) {
                this.$set(item, field, 0);
                this.makeToast('danger', 'Предупреждение', 'Требуется ввести число <= ' + max);
            }
        }, // форматирует введенное значение до digit цифр после запятой

        async itemUpdate(item) {
            this.$set(item, 'name_ru', null);
            this.$set(item, 'unit', null);
            this.$set(item, 'amount', 0);
            this.$set(item, 'cost_avg', 0);
            this.$set(item, 'itemToDelete', false);

            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;
        },

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$'); (^ и $ отмечают начало и конец строки соответственно)
            // ^[a-zA-Z]+$ - соответствует только строкам, которые состоят только из одной или нескольких букв
            // ^[0-9]+$ - ввод только цифр
            // ^-?\\d*\\d{0,9}$ - ввод отрицательных и положительных чисел
            // ^-?\\d*\\.?\\d{0,9}$ - ввод чисел с плавающей точкой
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            this.load = true;
            await this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                const values = await response.json();

                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicates равен true
                const listCheckDuplicates = [];

                await values.forEach(val => {
                    const item = {
                        id: val.id,
                        name_ru: val.name_ru,
                        unit: val.unit,
                        amount: val.amount,
                        cost_avg: val.cost_avg,
                        files: val.files,
                        row_files: val.row_files,
                        itemToDelete: false,
                        checkForDuplicates: !!listCheckDuplicates.find(el => el === val.recipient)
                    };

                    listCheckDuplicates.push(val.name_ru)
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            const total = that.number(item.amount) * that.number(item.cost_avg);
                            return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                        }
                    });
                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas', error.toString());
            }
            this.load = false;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'name_ru', row.name_ru);
                this.$set(item, 'unit', row.unit);
                this.$set(item, 'amount', parseInt(row.amount));
                this.$set(item, 'cost_avg', parseFloat(row.cost_avg));
                this.$set(item, 'row_files', row.row_files);

                values.push(item);
            }
            if (values.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    'Вы действительно хотите сохранить данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'ДА',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (this.budgetForm.find(item => item.checkForDuplicates === true)) {
                            this.makeToast('danger', 'Предупреждение', 'Данные не соответствуют контролю. Данные не сохранены!');
                        }
                        else {
                            if (value && this.variantAttribute) {
                                this.save(values);
                            }
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    });
            }
        },

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const response = await fetch('/api-py/save-brform' + this.form.code + '/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const response = await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        }
    }
};
</script>
<style scoped>
.filter-actions-flex {
    display: flex;
}
</style>